<template>

<div class="text">

	<div v-html="block.content" />

	<slot></slot>

</div>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock]

}

</script>

<style scoped>

.text {
	margin-bottom: 20px;
}

.text >>> h1,
.text >>> h2 {
	color: #1a234c;
	font-size: 24px;
	line-height: 30px;
	margin-bottom: 20px;
}

.text >>> h3 {
	color: #1277d4;
	margin-bottom: 20px;
	font-size: 21px;
	line-height: 30px;
}

.text >>> h4 {
	color: #1277d4;
	margin-bottom: 20px;
	font-size: 18px;
	line-height: 30px;
}

.text >>> h5 {
	color: #1277d4;
	margin-bottom: 20px;
	font-size: 16px;
	line-height: 30px;
}

.text >>> p {
	color: #343434;
	font-size: 16px;
	line-height: 30px;
	margin-bottom: 20px;
}

.is-mobile .text >>> h1,
.is-mobile .text >>> h2,
.is-mobile .text >>> h3,
.is-mobile .text >>> h4,
.is-mobile .text >>> h5 {
	text-transform: uppercase;
	font-weight: 300;
	text-align: center;
}

.is-mobile .text >>> p {
	font-size: 14px;
	line-height: 20px;
}

.text >>> ul {
	margin-bottom: 20px;
	list-style-type: disc;
	list-style-position: outside;
	margin-left: 20px;
}

.text >>> ol {
	margin-bottom: 20px;
	list-style-position: outside;
	list-style-type: numeric;
	margin-left: 20px;
}

.text >>> li {
	color: #343434;
	font-size: 16px;
	line-height: 30px;
}

.is-mobile .text >>> li {
	font-size: 14px;
	line-height: 20px;
}

.text >>> a {
	color: #1277d4;
	font-weight: bold;
}

.text >>> a:hover {
	text-decoration: underline;
}

.text >>> a.button {
	background-color: #1a234c;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
	border-radius: 5px;
	font-weight: 300;
	white-space: nowrap;
	height: 44px;
	line-height: 44px;
	min-width: 120px;
	padding: 0px 20px;
	font-size: 12px;
	letter-spacing: 1.2px;
}

.text >>> b,
.text >>> strong {
	font-weight: bold;
}

.text >>> i:not(.fa),
.text >>> em:not(.fa) {
	font-style: italic;
}

.text >>> i.fa,
.text >>> em.fa {
	width: 24px;
}

.text >>> img {
	margin-bottom: 20px;
	max-width: 100%;
	display: block;
}

.text >>> img[align="2"] {
	margin: 0px auto 20px auto;
}

.text >>> img[align="3"] {
	margin: 0px 0px 20px auto;
}

.text >>> img[align="0"] {
	width: 100%;
}

.text >>> *:last-child {
	margin-bottom: 0px;
}

.text >>> table {
	width: 100%;
	margin-bottom: 20px;
}

.text >>> td,
.text >>> th {
	color: #343434;
	font-size: 16px;
	line-height: 30px;
}
.is-mobile .text >>> td,
.is-mobile .text >>> th {
	font-size: 14px;
	line-height: 20px;
}

</style>
